import { graphql, Link } from 'gatsby'
import React, { useState } from 'react'
import Layout from "../components/Layout"

export default function ProductDetails({ data }) {
	const { html } = data.markdownRemark
	const { title, slug, num_photos } = data.markdownRemark.frontmatter
	const imgList = generateImgUrl(num_photos, slug)
	const [viewCurrentImg, setViewCurrentImg] = useState(imgList[0]);
	
	function expandImage(event) {
		console.log(event.target.src)
	}
	
	function generateImgUrl(numImages, slug) {
		let imgPath = `/img/products/${slug}`
		let items = [];
		numImages = numImages <= 5 ? numImages : 5

		for (let i = 1; i <= numImages; i++) {
			items.push(`${imgPath}/${i}.jpg`);
		}
		return items
	}

	function setActiveClass(element) {
		document.querySelector('.gallery-active')?.classList.remove('gallery-active')
		element.parentNode.classList.add('gallery-active')
	}

	return (
		<Layout>
			<section className="back-link" >
				<Link to="/products"> &#60; Каталог продукции</Link>
			</section>
			<section className="product-details">
				<div>
					<h3 className="product-title-min">{title}</h3>

					<div className="product-details-img" role="presentation" onClick={expandImage}>
						<img src={viewCurrentImg} alt="" />
					</div>

					<div className="product-details-gallery">
						{
							imgList.map(
								(imgUrl, index) => 
								<div className="product-details-gallery-box" key={index} role="presentation" onClick={({ target }) => {
									setViewCurrentImg(target.src);
									setActiveClass(target)
								}}>
									<img src={imgUrl}  alt=""  />
								</div>	
							)
						}
					</div>
				</div>

				<div>
					<h2 className="product-title-max">{title}</h2>

					<div className="product-details-content" dangerouslySetInnerHTML={{ __html: html }}>
					</div>
				</div>
			</section>
		</Layout>
	)
}

export const query = graphql`
query ProductDetails($slug: String) {
  markdownRemark(frontmatter: {slug: {eq: $slug}}) {
    frontmatter {
      	title
      	num_photos
		slug
    }
    html
  }
}
`
